import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import img1 from './img/img1.png';
import img2 from './img/img2.png';
import './CarouselComponent.css'; // Create this CSS file for custom styles

const CarouselComponent = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    return (
        <div className="carousel-container">
            <Slider {...settings}>
                <div className="carousel-slide">
                    <img src={img1} alt="Slide 1" />
                    <div className="carousel-caption">
                        <button>Order Now</button>
                    </div>
                </div>
                <div className="carousel-slide">
                    <img src={img2} alt="Slide 2" />
                    <div className="carousel-caption">
                        <button>Order Now</button>
                    </div>
                </div>
                
            </Slider>
        </div>
    );
};

export default CarouselComponent;