import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './navbar.css';

function Navbar() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    // Fetch login status from the API
    fetch('/login/check_login_status.php')
      .then(response => response.json())
      .then(data => {
        setIsLoggedIn(data.loggedIn);
      })
      .catch(error => {
        console.error('Error fetching login status:', error);
      });
  }, []);

  const handleLogout = () => {
    // Handle logout logic here
    fetch('/login/logout.php', { method: 'POST' })
      .then(() => {
        setIsLoggedIn(false);
      })
      .catch(error => {
        console.error('Error logging out:', error);
      });
  };

  return (
    <nav className="navbar navbar-expand-lg fixed-top">
      <div className="container-fluid">
        <Link className="navbar-brand me-auto" to="/">
          <img src="https://raw.githubusercontent.com/yashvinodbhaipatel/logo/main/logo.jpg" width="117" height="40" alt="logo" />
        </Link>

        <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
          <div className="offcanvas-header">
            <h5 className="offcanvas-title" id="offcanvasNavbarLabel">
              <img src="https://raw.githubusercontent.com/yashvinodbhaipatel/logo/main/logo.jpg" width="117" height="40" alt="logo" />
            </h5>
            <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close">X</button>
          </div>
          <div className="offcanvas-body">
            <ul className="navbar-nav justify-content-center flex-grow-1 pe-3">
              <li className="nav-item">
                <Link className="nav-link mx-lg-2" to="/">Home</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link mx-lg-2" to="/oder">Order Online</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link mx-lg-2" to="#">Store Locator</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link mx-lg-2" to="/franchiseenquiry">Franchise Enquiry</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link mx-lg-2" to="/about">Our Story</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link mx-lg-2" to="/contact">Contact Us</Link>
              </li>
            </ul>
          </div>
        </div>

        {isLoggedIn ? (
          <button className="logout-button" onClick={handleLogout}>Logout</button>
        ) : (
          <a href="https://fireaway-gujarat.com/login/" className="login-button">Login</a>
        )}

        <button className="navbar-toggler pe-0" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
      </div>
    </nav>
  );
}

export default Navbar;
