import React, { useState } from 'react';
import './order.css';

const order = () => {

  return (
    <div className="container">
      

    </div>
  );
};

export default order;