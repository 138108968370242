import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import './FranchiseEnquiry.css';

const FranchiseEnquiry = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const templateParams = {
      name: formData.name,
      email: formData.email,
      phone: formData.phone,
      message: formData.message,
    };

    emailjs.send(
      'service_w7e5r7d', // Replace with your EmailJS service ID
      'template_42l24wf', // Replace with your EmailJS template ID
      templateParams,
      'B8B1hrG0Q9WKgsHew' // Replace with your EmailJS user ID
    )
    .then((response) => {
      console.log('SUCCESS!', response.status, response.text);
      alert('Form submitted successfully!');
    }, (error) => {
      console.log('FAILED...', error);
      alert('Form submission failed. Please try again.');
    });
  };
  
  return (
    <div className="container">
      <h1 className="title">Franchise Enquiry</h1>
      <p className="text">
        Interested in becoming a part of Fireaway Pizza? Fill out the form below, and our team will get back to you with more information on how you can join us.
      </p>
      <form className="form" onSubmit={handleSubmit}>
        <input
          className="input"
          type="text"
          name="name"
          placeholder="Your Name"
          value={formData.name}
          onChange={handleChange}
          required
        />
        <input
          className="input"
          type="email"
          name="email"
          placeholder="Your Email"
          value={formData.email}
          onChange={handleChange}
          required
        />
        <input
          className="input"
          type="tel"
          name="phone"
          placeholder="Your Phone Number"
          value={formData.phone}
          onChange={handleChange}
          required
        />
        <textarea
          className="textarea"
          name="message"
          placeholder="Your Message"
          value={formData.message}
          onChange={handleChange}
          required
        />
        <button className="button" type="submit">Submit</button>
      </form>
    </div>
  );
};

export default FranchiseEnquiry;