import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import styled, { ThemeProvider } from 'styled-components';
import Navbar from './components/Navbar';
import Home from './pages/home';
import Order from './pages/order';
import StoreLocator from './pages/StoreLocator';
import FranchiseEnquiry from './pages/FranchiseEnquiry';
import About from './pages/About';
import Contact from './pages/Contact';
import Login from './pages/login';
import { darkTheme, lightTheme } from './utils/Themes';
import './App.css';

const Body = styled.div`
  background-color: ${({ theme }) => theme.bg};
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  height: 100vh;
`;

const Wrapper = styled.div`
  background: linear-gradient(38.73deg, rgba(204, 0, 187, 0.15) 0%, rgba(201, 32, 184, 0) 50%), linear-gradient(141.27deg, rgba(0, 70, 209, 0) 50%, rgba(0, 70, 209, 0.15) 100%);
  width: 100%;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 30% 98%, 0 100%);
`;

function App() {
  const [darkMode, setDarkMode] = useState(true);

  useEffect(() => {
    const preventZoom = (event) => {
      if (event.ctrlKey || event.metaKey) {
        event.preventDefault();
      }
    };

    const preventPinchZoom = (event) => {
      if (event.touches.length > 1) {
        event.preventDefault();
      }
    };

    window.addEventListener('wheel', preventZoom, { passive: false });
    window.addEventListener('keydown', (event) => {
      if ((event.ctrlKey || event.metaKey) && (event.key === '+' || event.key === '-' || event.key === '0')) {
        event.preventDefault();
      }
    });

    window.addEventListener('touchstart', preventPinchZoom, { passive: false });
    window.addEventListener('touchmove', preventPinchZoom, { passive: false });

    return () => {
      window.removeEventListener('wheel', preventZoom);
      window.removeEventListener('keydown', preventZoom);
      window.removeEventListener('touchstart', preventPinchZoom);
      window.removeEventListener('touchmove', preventPinchZoom);
    };
  }, []);

  console.log("App component rendered");

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <Router>
        <Navbar />
        <Body className="Body">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/order" element={<Order />} />
            <Route path="/storelocator" element={<StoreLocator />} />
            <Route path="/franchiseenquiry" element={<FranchiseEnquiry />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/login" element={<Login />} />
          </Routes>
        </Body>
      </Router>
    </ThemeProvider>
  );
}

export default App;
