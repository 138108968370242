import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import HeroSection from "../components/HeroSection";
import Skills from "../components/Skills";
import Experience from "../components/Experience";
import Projects from "../components/Projects"; // Corrected path
import Footer from "../components/Footer"; // Corrected path
import Education from "../components/Education";
import './home.css';

const Wrapper = styled.div`
  background: linear-gradient(38.73deg, rgba(204, 0, 187, 0.15) 0%, rgba(201, 32, 184, 0) 50%), linear-gradient(141.27deg, rgba(0, 70, 209, 0) 50%, rgba(0, 70, 209, 0.15) 100%);
  width: 100%;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 30% 98%, 0 100%);
`;

const Home = () => {
  const [phone, setPhone] = useState(null);

  useEffect(() => {
    // Fetch session data from the API
    fetch('/login/get_session_data.php')
      .then(response => response.json())
      .then(data => {
        if (data.phone) {
          setPhone(data.phone);
        }
      })
      .catch(error => {
        console.error('Error fetching session data:', error);
      });
  }, []);

  return (
    <div>
      <HeroSection />
      <Wrapper>
        <Skills />
        {phone && (
          <div className="phone-display">
            <p>Logged in as: {phone}</p>
          </div>
        )}
        <Experience />
      </Wrapper>
      {/* <Wrapper>
        <Education />
        <Projects />
      </Wrapper> */}
      {/* <Footer /> */}
    </div>
  );
};

export default Home;
