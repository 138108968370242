// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBylP52F9eZS5m6dOR2UZ5hLoMvBO3aLPs",
  authDomain: "fireaway-gujarat-e56dd.firebaseapp.com",
  projectId: "fireaway-gujarat-e56dd",
  storageBucket: "fireaway-gujarat-e56dd.appspot.com",
  messagingSenderId: "769249059145",
  appId: "1:769249059145:web:8b2630414640105e247a98",
  measurementId: "G-FQ6LRTRGST"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
