import React from 'react';
import styled from 'styled-components';
import { skills } from '../../data/constants';

// Styled components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 1;
  align-items: center;
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 1100px;
  gap: 12px;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

export const Title = styled.div`
  font-size: 42px;
  text-align: center;
  font-weight: 600;
  margin-top: 20px;
  color: ${({ theme }) => theme.text_primary};
  @media (max-width: 768px) {
    margin-top: 12px;
    font-size: 32px;
  }
`;

export const Desc = styled.div`
  font-size: 18px;
  text-align: center;
  max-width: 600px;
  
  color: ${({ theme }) => theme.text_secondary};
  font-size: 16px;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const SkillsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  gap: 30px;
  justify-content: center;
`;

const Skill = styled.div`
  width: 100%;
  max-width: 500px;
  background: ${({ theme }) => theme.card};
  border: 0.1px solid #854CE6;
  box-shadow: rgba(23, 92, 230, 0.15) 0px 4px 24px;
  border-radius: 16px;
  padding: 18px 36px;
  @media (max-width: 768px) {
    max-width: 400px;
    padding: 10px 36px;
  }
  @media (max-width: 500px) {
    max-width: 330px;
    padding: 10px 36px;
  }
`;

const SkillTitle = styled.h2`
  font-size: 28px;
  font-weight: 600;
  color: ${({ theme }) => theme.text_secondary};
  margin-bottom: 20px;
  text-align: center;
`;

const IframeWrapper = styled.div`
  width: 100%;
  height: 350px; /* Adjust the height as needed */
  margin-top: 20px;
`;

const videoData = [
  {
    url: 'https://www.youtube.com/embed/fiOJ3IhPMs0',
    title: 'First Video',
    desc: 'We Use Fresh Doo, Not Preserved'
  },
  {
    url: 'https://www.youtube.com/embed/Aw7_yq_orkw',
    title: 'Second Video',
    desc: 'We Make Fresh Pizza Base'
  },
  {
    url: 'https://www.youtube.com/embed/AI3Km-GuoOA',
    title: 'Third Video',
    desc: 'Get Ready Gujarat, We Are Coming Soon To Your City.'
  }
];

const Skills = () => {
  return (
    <Container id="skills">
      <Wrapper>
        <Title>VIDEO</Title>
        <Desc>Get Ready Gujarat, We Are Coming Soon To Your City.</Desc>
        <SkillsContainer>
          {videoData.map((video, index) => (
            <Skill key={index}>
              <IframeWrapper>
                <Desc>{video.desc}</Desc>
                <iframe
                  src={video.url}
                  title={video.title}
                  style={{ width: '100%', height: '90%', border: 'none' }}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </IframeWrapper>
            </Skill>
          ))}
        </SkillsContainer>
      </Wrapper>
    </Container>
  );
};

export default Skills;
