import React from 'react';
import './About.css';

const About = () => {
  return (
    <div className="container">
      <h1 className="title">About Fireaway Pizza</h1>
      <p className="text">
        Welcome to Fireaway Pizza, your ultimate destination for delicious and freshly made pizzas in Gujarat. We are proud to be a part of the vibrant and diverse community here, bringing you a variety of mouth-watering pizzas and other food items.
      </p>
      <h2 className="subtitle">Our Mission</h2>
      <p className="text">
        Our mission is to provide the best quality food with a seamless online ordering experience. We offer a wide range of pizzas and other food options, ensuring there's something for everyone. At Fireaway Pizza, we believe in delivering exceptional value and service.
      </p>
      <h2 className="subtitle">Meet Our Team</h2>
      <p className="text">
        Owner: Patel Rahul
        <br />
        Technical Developer: Patel Yash
      </p>
      <h2 className="subtitle">Online Transactions</h2>
      <p className="text">
        Our website allows all types of online transactions. In the rare event that a transaction fails, we guarantee a refund within 24 hours to your bank account.
      </p>
      <h2 className="subtitle">Offers</h2>
      <p className="text">
        We have a variety of food offers available. Check our website regularly for the latest deals and promotions.
      </p>
      <div className="contact">
        <h2 className="subtitle">Contact Us</h2>
        <p className="text">
          For any queries, please contact us at: 
          <a href="mailto:info@fireaway-gujarat.com"> info@fireaway-gujarat.com</a>
        </p>
      </div>
    </div>
  );
};

export default About;
